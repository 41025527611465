@import "~bootstrap";
@import "./base";

/* Administration Forms
 ------------------------------------------ */

form.smweb-admin-form {

  margin-top: 20px;

  label {
    color: white;
  }

  input.smweb-form-control,
  select.smweb-form-control {
    display: block;
    width: 100%;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: white;
    background-color: rgba(255, 255, 255, .1);
    border: none;
    border-left: 3px solid $color-one;
    border-radius: 0px;
    outline: none;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    &:focus + * {
      width: 100%;
    }
  }

  .errors {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      color: $red;
      font-size: 85%;
    }
  }

  .bottom-line {
    display: block;
    height: 1px;
    width: 0;
    background-color: $color-two;
    transition: width 0.2s linear;
  }

  .form-submit {
    margin-top: 20px;
    border-top: 1px solid $color-two;
    padding: 20px 0;

    button[type="submit"] {
      padding: 15px 40px;
      border-radius: 0;

      &:hover {
        border-color: $color-one;
      }
    }
  }
}
