/* Administration mixins
 ------------------------------------------ */

@mixin border-radius($property) {
  -webkit-border-radius: $property;
  -moz-border-radius: $property;
  border-radius: $property;
}

@mixin transition($duration) {
  -moz-transition: all $duration;
  -o-transition: all $duration;
  -webkit-transition: all $duration;
  transition: all $duration;
}

@mixin transition($duration) {
  -moz-transition: all $duration;
  -o-transition: all $duration;
  -webkit-transition: all $duration;
  transition: all $duration;
}

@mixin rotate($rotate) {
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  -o-transform: rotate($rotate);
}

@mixin admin-btn() {
  cursor: pointer;
  margin: 0;
  padding: 10px 25px;
  color: white;
}

@mixin btn-admin($p-color, $s-color) {

  @include border-radius(100px);
  @include transition(0.3s);

  padding: 5px 15px;
  margin: 0;

  color: $s-color;
  background-color: $p-color;

  display: inline-block;
  position: relative;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background-color: $s-color;
    color: $p-color;
  }
}
